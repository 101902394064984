<template>
  <div class="main-view-con">
    <el-table :data="data.data || []" border v-loading="isLoading">
      <el-table-column prop="id" label="ID" width="100" />
      <el-table-column prop="uid" label="用户ID" />
      <el-table-column prop="createTime" label="创建时间" #default="{ row }">
        <span>{{ $filters.dateFilter(row.createTime * 1000) }}</span>
      </el-table-column>
      <el-table-column prop="reward" label="奖品" />
    </el-table>
    <div class="table-page">
      <el-pagination
        background
        layout="prev, pager, next, total"
        :total="data.total || 0"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "lotteryRecord"
};
</script>
<script setup>
import { reactive, watch, defineProps, onActivated } from "vue";
import { debounce } from "@/utils";
import { lotteryLog } from "@/api/activity";
import { useAsync } from "@/utils/useAsync";

const props = defineProps({
  id: {
    type: String,
    required: true
  }
});

const searchParam = reactive({
  page: 1,
  paginate: 10
});

const { data, run, isLoading } = useAsync();

watch(
  [searchParam],
  debounce(() => {
    run(lotteryLog({ ...searchParam, actId: props.id }));
  }, 500),
  {
    immediate: true
  }
);

const handleCurrentChange = (val) => {
  searchParam.page = val;
};

onActivated(() => {
  run(lotteryLog({ ...searchParam, actId: props.id }));
});
</script>

<style scoped></style>
